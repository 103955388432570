import React, { useState } from 'react';
import { Grid, Skeleton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { getRef, uuidv4 } from '../../../../utils/functions';
import ProductCategoryCard from '../../../cards/categoryCard/dutchCategoryCard';
import { useSmall } from '../../../../hooks';
import style from './style';
import ShareLink from '../../../../containersV2/shareLink';

const useStyles = makeStyles(style);

function ProductsList({ products, isAction, loading }) {
  const theme = useTheme();
  const classes = useStyles();

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  }

  return (
    <>
    <Grid
      container
      // spacing={isSmall ? 1 : 3}
      // direction="row"
      // justifyContent="center"
      spacing={{ xs: 1, sm: 3, md: 3 }}
      className={classes.dutchItemContainer}
    >
      {(loading ? Array.from(new Array(4)) : products).map(product => {
        // console.log('product: ', product);
        const newProduct = {
          ...product,
          styles: {
            textColor: theme.palette.text.bright,
            justifyContent: 'space-between',
            padding: theme.spacing(5),
            titleFontSize: theme.typography.h4.fontSize,
            subTitleFontSize: theme.typography.subtitle2.fontSize,
            titleTextColor: product?.textColor,
            subTitleTextColor: product?.subTitleColor,
            buttonBorder:
              product?.background === theme?.palette?.background?.default
                ? `1px solid ${theme?.palette?.primary?.main}`
                : 'none',
          },
        };
        if (product) {
          return (
            <Grid
              key={newProduct?.lob || uuidv4()}
              item
              xs="12"
              sm="6"
              lg="3"
              className={classes.dutchItem}
            >
              <ProductCategoryCard
                product={newProduct}
                isAction={isAction}
                loading={loading}
                openModel={handleOpen}
              />
            </Grid>
          );
        }
        return (
          <Grid
            key={newProduct?.lob || uuidv4()}
            item
            xs="12"
            sm="6"
            lg="3"
            className={classes.loaderCard}
          >
            <Skeleton animation="wave" variant="rectangle" height={250} />
          </Grid>
        );
      })}
      </Grid>
      <ShareLink isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </>
  );
}

export default ProductsList;
