import { strapiGetCall } from '../axios';

const getGenericContents = (state, params) => {
  const url = `/gg-generic-content-sections`;
  return strapiGetCall(state, url, params);
};

const getSurvey = (state, params) => {
  const url = `/c-360-survey-events`;
  return strapiGetCall(state, url, params);
};

export default { getGenericContents, getSurvey };
