import {
  GET_GENERIC_CONTENTS_API_REQUEST,
  GET_GENERIC_CONTENTS_API_SUCCESS,
  GET_GENERIC_CONTENTS_API_ERROR,
} from '../../../constants/miscActionNames';

// import { api } from '../../../utils/variables';
import getGenericContentsApi from '../../../apis/misc';

function getGenericContentsError(err) {
  return {
    type: GET_GENERIC_CONTENTS_API_ERROR,
    payload: err,
  };
}

function getGenericContentsRequest() {
  return {
    type: GET_GENERIC_CONTENTS_API_REQUEST,
    payload: true,
  };
}
function getGenericContentsSuccess(content) {
  return {
    type: GET_GENERIC_CONTENTS_API_SUCCESS,
    payload: content,
  };
}

export function getGenericContentsAction(params) {
  // const apiEndpoint = {}; // ...api };

  return (dispatch, getState) => {
    const state = getState();
    dispatch(getGenericContentsRequest());
    return getGenericContentsApi
      .getGenericContents(state, params)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(getGenericContentsSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(getGenericContentsError(error));
        throw error;
      });
  };
}

export default {
  getGenericContentsAction,
};
