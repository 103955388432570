import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// we import two react components from react-router-dom, one is Route which
// let's us to route to an individual page and the other is Switch
// which lets us break our routes into sections
// import { useSelector } from 'react-redux';
import Loadable from 'react-loadable';
import NotFound from '../componentsV2/common/notFound';
import Loading from '../componentsV2/common/loading';
// import InsuranceSummaryPage from '../containersV2/portfolio/portfolioSummary';
// import SignOutPage from '../containers/account/signOut';
// in the app we have two layouts which gives a template structure for our pages.
// One layout is applied to all our authentication pages, and is called AuthLayout
// the other layout is applied to the rest of our app, and is called MainLayout
// import Auth from './auth';
// import Main from './main';
// Auth and Main are sub-route directories placed in react components
// to section of the the routes with different layouts
import routes from '../utils/variables/routes';
import UnAuthenticatedRoute from './unAuthenticatedRoute';
import ModalCountrySelector from '../containersV2/selectLocation/modalCountrySelector';
import { resAlpha2 } from '../utils/variables';

// import NormalRoute from './normalRoute';

const AuthSection = Loadable({
  loader: () => import('./auth'),
  loading: () => <Loading addBottomNav={false} addFooter={false} addHeader={false} />,
});

const MainSection = Loadable({
  loader: () => import('./main'),
  loading: () => <Loading addBottomNav={false} addFooter={false} addHeader={false} />,
});

const SiteOffline = Loadable({
  loader: () => import('../containersV2/offline'),
  loading: () => <Loading addBottomNav={false} addFooter={false} addHeader={false} />,
});

const SelectLocationPage = Loadable({
  loader: () => import('../containersV2/selectLocation'),
  loading: () => <Loading addBottomNav={false} addFooter={false} addHeader={false} />,
});

const Routes = ({
  siteOffline = false,
  isRobot = false,
  serviceOfflineRoutes = [],
  redirectRoutes = [],
  alpha2,
  initialLocationSetting,
  showCountrySelection,
  allLocations,
  resLocationSetting,
}) => {
  // console.log('initialLocationSetting: ', initialLocationSetting);
  return (
    <>
      {showCountrySelection && initialLocationSetting?.type === 'modal' && (
        <ModalCountrySelector options={initialLocationSetting?.options} alpha2={alpha2} />
      )}

      <Switch>
        {siteOffline && !isRobot && <Route component={SiteOffline} />}
        {showCountrySelection && initialLocationSetting?.type === 'page' && (
          <Route
            component={props => (
              <SelectLocationPage
                {...props}
                options={initialLocationSetting?.options}
                disableSeo
              />
            )}
          />
        )}
        <Route
          exact
          path={routes.selectLocation.index}
          component={props => {
            return (
              <SelectLocationPage {...props} options={resLocationSetting?.options} />
            );
          }}
        />

        {allLocations &&
          allLocations?.length > 0 &&
          !initialLocationSetting &&
          alpha2 === resAlpha2 && (
            <Route
              component={props => (
                <SelectLocationPage
                  {...props}
                  options={allLocations?.map(x => ({
                    title: x?.name,
                    alpha2: x?.alpha2,
                  }))}
                />
              )}
            />
          )}

        {serviceOfflineRoutes?.map(newRoute => {
          if (
            newRoute?.inActive ||
            (alpha2 &&
              newRoute?.territory &&
              newRoute?.territory?.toLowerCase() !== alpha2?.toLowerCase())
          ) {
            return null;
          }
          return (
            <Route
              exact={newRoute?.exact}
              path={newRoute?.url}
              component={() => <Redirect to={routes.serviceOffline.index} />}
            />
          );
        })}
        {/*
      <Route
        exact
        path="/wizards/motor-quote"
        render={() => <Redirect to={routes.serviceOffline.index} />}
      />
      <Route
        exact
        path="/wizards/home-quote"
        render={() => <Redirect to={routes.serviceOffline.index} />}
      />
      */}

        {redirectRoutes?.map(newRoute => {
          if (
            newRoute?.inActive ||
            (alpha2 &&
              newRoute?.territory &&
              newRoute?.territory?.toLowerCase() !== alpha2?.toLowerCase())
          ) {
            return null;
          }
          return (
            <Route
              exact={newRoute?.exact}
              path={newRoute?.url}
              render={() => <Redirect to={newRoute?.redirectUrl} />}
            />
          );
        })}

        <Route
          exact
          path={routes.portfolio.oldInsuranceSummary}
          render={() => <Redirect to={routes.portfolio.portfolioSummary} />}
        />
        <Route
          exact
          path={routes.portfolio.insuranceSummary}
          render={() => <Redirect to={routes.portfolio.portfolioSummary} />}
        />
        <Route
          exact
          path={routes.portfolio.investmentSummary}
          render={() => <Redirect to={routes.portfolio.newInvestmentSummary} />}
        />
        <Route
          exact
          path={routes.portfolio.oldInvestmentSummary}
          render={() => <Redirect to={routes.portfolio.newInvestmentSummary} />}
        />
        <Route
          exact
          path={routes.wizards.digitalSales}
          render={() => <Redirect to={routes.wizards.application} />}
        />
        <Route
          exact
          path="/autopay"
          render={() => <Redirect to={routes.cart.autoPayCenter} />}
        />
        <Route
          exact
          path="/recurring"
          render={() => <Redirect to={routes.cart.autoPayCenter} />}
        />
        <Route
          exact
          path="/custompay"
          render={() => <Redirect to={routes.general.gettingStartedCustomPay} />}
        />
        <Route
          exact
          path="/agent"
          render={() => <Redirect to={routes.auth.insuranceRep} />}
        />
        <Route
          exact
          path="/corporate"
          render={() => <Redirect to={routes.auth.corporate} />}
        />
        <Route
          exact
          path="/insurancerep"
          render={() => <Redirect to={routes.auth.insuranceRep} />}
        />
        <Route
          exact
          path="/auth/signin"
          render={() => <Redirect to={routes.auth.signIn} />}
        />
        <Route
          exact
          path="/cookie-policy"
          render={() => <Redirect to={routes.general.cookiePolicy} />}
        />
        <Route
          exact
          path="/privacy-policy"
          render={() => <Redirect to={routes.general.privacyPolicy} />}
        />
        <Route
          exact
          path="/terms-and-conditions"
          render={() => <Redirect to={routes.general.termsAndConditions} />}
        />

        <UnAuthenticatedRoute
          path={routes.auth.index}
          component={props => <AuthSection {...props} />}
        />

        <Route path={routes.index} component={props => <MainSection {...props} />} />

        {/*
      <NormalRoute
        exact
        path={routes.portfolio.portfolioSummary}
        component={InsuranceSummaryPage}
      />
      <Route
        exact
        path={routes.portfolio.index}
        // component={InsurancePortfolioSummary}
        component={InsuranceSummaryPage}
      />

      <Route exact path={routes.account.signOut} component={SignOutPage} />
      */}

        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;

/*

      <Route exact path={routes.index} component={Loader} />

      */
