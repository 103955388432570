// import { api } from '../../../utils/variables';
import { referralVerificationApi } from '../../../apis/auth';
import { updateTempState } from '../../temp';

export function referralVerificationAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    return referralVerificationApi
      .referralVerification(state, params)
      .then(data => {
        const referralWebstarNumber = Array.isArray(data?.payload?.webstarDetails)
          ? data?.payload?.webstarDetails?.find(
            webstarNumber => webstarNumber?._id === params?.commission,
          )
          : null;

        dispatch(
          updateTempState.updateTempStateAction({
            referralDetails: {
              commissionId: params?.commission,
              ...data?.payload,
              ...(referralWebstarNumber &&
                referralWebstarNumber?.country &&
                referralWebstarNumber?.producerNumber &&
                referralWebstarNumber?.commissionNumber && {
                referralWebstarDetails: `${referralWebstarNumber?.country} ${referralWebstarNumber?.producerNumber}/${referralWebstarNumber?.commissionNumber}`,
              }),
            },
          }),
        );
        return data;
      })
      .catch(error => {
        throw error;
      });
  };
}

export default {
  referralVerificationAction,
};
